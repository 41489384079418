import React, { useContext } from "react";
import Buttons from "../../components/buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";
import heroImage from "../../assets/img/hero-image-gradient.png";
import { ReactComponent as SnailIcon } from "../../assets/svg/landing-page/snail-icon.svg";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";

function Section1Hero() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <div className="flex justify-between px-[100px] -mt-[100px]">
          <div className="w-7/12">
            <p className="text-[152px] leading-[100px] text-ategbe-font-white font-AfricaVibesRegular cursor-default">
              {currentLanguage === "portuguese"
                ? "responda ao chamado de seus antepassados"
                : "answer the call of your ancestors"}
            </p>
            {currentLanguage === "portuguese" ? (
              <p className="w-[650px] mt-[24px] text-[18px] leading-[32.2px] text-[#D9D9D9] font-FaktumRegular cursor-default">
                Bem-vindo ao Ategbe Movement, estamos constantemente
                ultrapassando os limites do a Cultura Yoruba através de
                recursos, roupas, mercadorias e filmando ativamente
                documentários de classe mundial. Viaje, aprenda e Descubra
                conosco.
              </p>
            ) : (
              <p className="w-[650px] mt-[24px] text-[18px] leading-[32.2px] text-[#D9D9D9] font-FaktumRegular cursor-default">
                Welcome to Ategbe Movement, we are constantly pushing the
                boundaries of the Yoruba Culture through resources, clothing,
                merchandise and actively filming world-class documentaries.
                Journey, Learn and Discover with us.
              </p>
            )}
            <div className="flex items-center justify-between my-[40px] w-[230px]">
              <Buttons
                specificButtonTitle={
                  currentLanguage === "portuguese" ? "RESPONDER" : "ANSWER"
                }
                specificButtonText={
                  currentLanguage === "portuguese" ? "RESPONDER" : "ANSWER"
                }
                specificButtonTextStyling="
                button-transition-css
                border shadow-css mr-4 px-8 py-4 border-ategbe-green rounded-md
                font-FaktumBold text-center text-lg text-[#D9D9D9] lg:whitespace-nowrap"
                specificButtonClick={null}
              />
              <SnailIcon className="cursor-pointer" title="ANSWER!" />
            </div>
          </div>
          <div className="w-5/12">
            <div>
              <img src={heroImage} alt="hero" className="w-[645px] h-[645px]" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full mt-[4px] h-full flex items-center justify-center">
        <div className="px-[40px]">
          <div className="">
            <p className="text-[80px] text-centr leading-[68.1px] text-ategbe-font-white font-AfricaVibesRegular">
              {currentLanguage === "portuguese"
                ? "responda ao chamado de seus antepassados"
                : "answer the call of your ancestors"}
            </p>
            {currentLanguage === "portuguese" ? (
              <p className="mt-[25px] text-[14px] leading-[20.8px] text-[#D9D9D9] font-FaktumRegular">
                Bem-vindo ao Ategbe Movement, estamos constantemente
                ultrapassando os limites do a Cultura Yoruba através de
                recursos, roupas, mercadorias e filmando ativamente
                documentários de classe mundial. Viaje, aprenda e Descubra
                conosco.
              </p>
            ) : (
              <p className="mt-[25px] text-[14px] leading-[20.8px] text-[#D9D9D9] font-FaktumRegular">
                Welcome to Ategbe Movement, we are constantly pushing the
                boundaries of the Yoruba Culture through resources, clothing,
                merchandise and actively filming world-class documentaries.
                Journey, Learn and Discover with us.
              </p>
            )}
            <div className="flex items-center justify-between mt-[35px] w-[180px]">
              <Buttons
                specificButtonTitle={
                  currentLanguage === "portuguese" ? "RESPONDER" : "ANSWER"
                }
                specificButtonText={
                  currentLanguage === "portuguese" ? "RESPONDER" : "ANSWER"
                }
                specificButtonTextStyling="border shadow-css mr-4 px-4 py-2 border-ategbe-green
                 rounded-md font-FaktumBold text-center text-base text-[#D9D9D9] lg:whitespace-nowrap"
                specificButtonClick={null}
              />
              <SnailIcon className="cursor-pointer" title="ANSWER!" />
            </div>
          </div>
          <div className="flex items-center justify-center mt-[35px] ">
            <div>
              <img src={heroImage} alt="hero" className="w-[304px] h-[384px]" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16 -mt-28" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6 -mt-2" />
        </>
      )}
    </div>
  );
}

export default Section1Hero;
