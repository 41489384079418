import React, { useContext } from "react";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";
import ategbeTalks from "../../assets/img/ategbe-talks.png";
import ategbeMerch from "../../assets/img/ategbe-merch.png";
import ategbeCourses from "../../assets/img/ategbe-courses.png";
import Buttons from "../../components/buttons/buttons";

function ResourcesCard({ image, name, description, buttonTitle, buttonText }) {
  return (
    <div
      className="cursor-pointer w-[350px] h-[480px] pb-5  rounded-[25px] border-2 border-ategbe-green hover:border-white flex items-center justify-center
    transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
    >
      <div>
        <div className="flex items-center justify-center">
          <img
            alt="ate"
            src={image}
            className="w-[120px] h-[120px] rounded-md"
          />
        </div>
        <p className="text-ategbe-font-white text-[45px] text-center font-AfricaVibesRegular">
          {name}
        </p>
        <div className="px-[35px]">
          <p className="text-ategbe-font-white text text-[15px] mt-[10px] text-center font-FaktumRegular">
            {description}
          </p>
        </div>
        <div className="flex items-center justify-center">
          <Buttons
            specificButtonTitle={buttonTitle}
            specificButtonText={buttonText}
            specificButtonStyling="px-6 py-2 mt-8 w-[150px] rounded-full border border-ategbe-green 
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:border-white duration-700"
            specificButtonTextStyling="font-FaktumBold text-[20px] text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap hover:border-white"
          />
        </div>
      </div>
    </div>
  );
}

function ResourcesCardMobile({
  image,
  name,
  description,
  buttonTitle,
  buttonText,
}) {
  return (
    <div
      className="my-10 cursor-pointer w-[280px] rounded-[25px] pb-6 border-2 border-ategbe-green flex items-center justify-center
    transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-700"
    >
      <div>
        <div className="flex items-center justify-center">
          <img alt="ate" src={image} className="w-[120px] h-[120px]" />
        </div>
        <p className="text-ategbe-font-white text-[45px] text-center font-AfricaVibesRegular">
          {name}
        </p>
        <div className="px-[20px]">
          <p className="text-ategbe-font-white text text-[15px] mt-[10px] text-center font-FaktumRegular">
            {description}
          </p>
        </div>
        <div className="flex items-center justify-center ">
          <Buttons
            specificButtonTitle={buttonTitle}
            specificButtonText={buttonText}
            specificButtonStyling="px-4 py-2 my-[15px] w-[150px] rounded-full border border-ategbe-green 
            transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:border-white duration-700"
            specificButtonTextStyling="font-FaktumBold text-[13px] leading-[18px] text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap hover:border-white"
          />
        </div>
      </div>
    </div>
  );
}

function Section3Resources() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="px-[150px] pb-[20px] w-full">
          <p className="text-center mb-[50px] text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular cursor-default">
            {currentLanguage === "portuguese" ? "recursos" : "resources"}
          </p>
          <div className="flex items-center justify-between ">
            <ResourcesCard
              image={ategbeTalks}
              name={
                currentLanguage === "portuguese" ? "isese fala" : "isese talks"
              }
              description={
                currentLanguage === "portuguese"
                  ? `Engajamos com nossa audiência e público geral através de podcasts que servem 
                  de suporte motivacional para os aderentes ao Isese, enquanto focamos
                  em diferentes aspectos e desafios do dia a dia do devoto e como lidar com eles.`
                  : `We engage with our audiences and the general public with several
                  podcast programmes that serves as motivational support for Isese adherents as they 
                  focus on different aspects of the day to day life challenges of the Isese adherents 
                  and how they could cope with them.`
              }
              buttonTitle={
                currentLanguage === "portuguese" ? "Ouvir" : "Listen"
              }
              buttonText={currentLanguage === "portuguese" ? "Ouvir" : "Listen"}
            />
            <ResourcesCard
              currentLanguage={currentLanguage}
              image={ategbeMerch}
              name={
                currentLanguage === "portuguese" ? "mercadoria" : "merchandise"
              }
              description={
                currentLanguage === "portuguese"
                  ? `Vestuário Ategbe: Vista-se adequadamente para sua jornada espiritual, mergulhe na religião 
                  Isese com nossa coleção de roupas Isese autênticas. nós temos o traje para vestir você para sair com estilo.
                  Nossas roupas são feitas com materiais de alta qualidade e atenção meticulosa aos detalhes.`
                  : `Ategbe Apparel: Dress the Part for Your Spiritual Journey, Immerse yourself in the Isese religion 
                  with our collection of authentic Isese garments. we have the attire to outfit you to step out in style. 
                  Our garments are made with high-quality materials and meticulous attention to detail.`
              }
              buttonTitle={
                currentLanguage === "portuguese" ? "Comprar" : "Shop"
              }
              buttonText={currentLanguage === "portuguese" ? "Comprar" : "Shop"}
            />
            <ResourcesCard
              currentLanguage={currentLanguage}
              image={ategbeCourses}
              name={
                currentLanguage === "portuguese"
                  ? "ategbe cursos"
                  : "ategbe courses"
              }
              description={
                currentLanguage === "portuguese"
                  ? `A companhia ajuda na revitalização e resgate da extinção de ambas as partes, 
                  tangíveis e intangíveis, de nossa herança. Histórias orais do passado são documentadas
                   enquanto cobrimos e promovemos eventos no presente e festivais através de nossos 
                   especialistas em videografia.`
                  : ` The company helps in the rescue revival both the tangible and 
                  intangible part of your heritage from going into extinction. Past oral histories 
                  are helped to get documented while we also cover and promote your present events or 
                  festival through our experts in the videography department.`
              }
              buttonTitle={
                currentLanguage === "portuguese" ? "Descobrir" : "Discover"
              }
              buttonText={
                currentLanguage === "portuguese" ? "Descobrir" : "Discover"
              }
            />
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[6px] flex items-center justify-center">
        <div className="px-[20px] pt-[40px] pb-[40px] w-10/12">
          <p className="text-center text-[56px] leading-[51px] text-ategbe-font-white font-AfricaVibesRegular">
            {currentLanguage === "portuguese" ? "recursos" : "resources"}
          </p>
          <div className="flex items-center justify-center">
            <div>
              <ResourcesCardMobile
                image={ategbeTalks}
                name={
                  currentLanguage === "portuguese"
                    ? "isese fala"
                    : "isese talks"
                }
                description={
                  currentLanguage === "portuguese"
                    ? `Engajamos com nossa audiência e público geral através de podcasts que servem 
                  de suporte motivacional para os aderentes ao Isese, enquanto focamos
                  em diferentes aspectos e desafios do dia a dia do devoto e como lidar com eles.`
                    : `We engage with our audiences and the general public with several
                  podcast programmes that serves as motivational support for Isese adherents as they 
                  focus on different aspects of the day to day life challenges of the Isese adherents 
                  and how they could cope with them.`
                }
                buttonTitle={
                  currentLanguage === "portuguese" ? "Ouvir" : "Listen"
                }
                buttonText={
                  currentLanguage === "portuguese" ? "Ouvir" : "Listen"
                }
              />
              <ResourcesCardMobile
                image={ategbeMerch}
                name={
                  currentLanguage === "portuguese"
                    ? "ifa ensinamentos"
                    : "ifa teachings"
                }
                description={
                  currentLanguage === "portuguese"
                    ? `A companhia oferece as melhores práticas de iniciação no Isese através de nossos especialistas. 
                  Também oferecemos treinamento para aqueles que pretendem se tornar sacerdotes
                  na religião tradicional através de nossos profissionais.`
                    : `The company through its religious experts engages in the world
                  best practices of initiations into the Isese religion. We also offer training 
                  sessions to intending Priest and priestesses of the Isese religion through our 
                  professionals.`
                }
                buttonTitle={
                  currentLanguage === "portuguese" ? "Aprender" : "Learn"
                }
                buttonText={
                  currentLanguage === "portuguese" ? "Aprender" : "Learn"
                }
              />
              <ResourcesCardMobile
                image={ategbeCourses}
                name={
                  currentLanguage === "portuguese"
                    ? "ategbe cursos"
                    : "ategbe courses"
                }
                description={
                  currentLanguage === "portuguese"
                    ? `A companhia ajuda na revitalização e resgate da extinção de ambas as partes, 
                  tangíveis e intangíveis, de nossa herança. Histórias orais do passado são documentadas
                   enquanto cobrimos e promovemos eventos no presente e festivais através de nossos 
                   especialistas em videografia.`
                    : ` The company helps in the rescue revival both the tangible and 
                  intangible part of your heritage from going into extinction. Past oral histories 
                  are helped to get documented while we also cover and promote your present events or 
                  festival through our experts in the videography department.`
                }
                buttonTitle={
                  currentLanguage === "portuguese" ? "Descobrir" : "Discover"
                }
                buttonText={
                  currentLanguage === "portuguese" ? "Descobrir" : "Discover"
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section3Resources;
