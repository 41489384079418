import React, { useContext } from "react";
import Buttons from "../../components/buttons/buttons";
import { useWindowResize } from "../../helpers/useWindowResize";
import { LanguageContext } from "../../utils/context";
import footerPattern from "../../assets/img/footer-pattern.png";

function Section5Videos() {
  const { width } = useWindowResize();
  const { currentLanguage } = useContext(LanguageContext);

  const videosUrls = [
    {
      id: 1,
      url: "https://www.youtube.com/embed/ix5ZgxXtuac?si=HMdVKJqEqZl2dDmG",
    },
    {
      id: 2,
      url: "https://www.youtube.com/embed/Hf1dvIUFz3I?si=6bZdjJpYcksyd4dD",
    },
    {
      id: 3,
      url: "https://www.youtube.com/embed/lOSwYJeuIXc?si=2cOQWoXHrZnHhxSr",
    },
  ];

  /** Displays Desktop */
  const desktop = () => {
    return (
      <div className="w-full flex items-center justify-center my-10">
        <div className="px-[150px] pb-[20px] w-full">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese"
                  ? "séries documentais"
                  : "Docuseries"
              }
              specificButtonTextStyling="cursor-default text-center mb-[50px] text-[106px] leading-[81px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>

          <div className="flex justify-between">
            {videosUrls.map((video) => (
              <div key={video.id}>
                <iframe
                  className="rounded-xl"
                  // width="960"
                  height="515"
                  src={video.url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  /** Displays Mobile */
  const mobile = () => {
    return (
      <div className="w-full h-full my-[6px] flex items-center justify-center">
        <div className="px-[20px] pt-[40px] pb-[40px] w-10/12">
          <div className="flex items-center justify-center">
            <Buttons
              specificButtonText={
                currentLanguage === "portuguese"
                  ? "séries documentais"
                  : "Docuseries"
              }
              specificButtonTextStyling="cursor-default text-center mb-[35px] text-[56px] leading-[48px] text-ategbe-font-white font-AfricaVibesRegular"
            />
          </div>

          <div className="flex justify-center">
            <div>
              {videosUrls.map((video) => (
                <div className="flex flex-col" key={video.id}>
                  <iframe
                    className="rounded-xl my-6"
                    width="300"
                    height="515"
                    src={video.url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1188 ? (
        <>
          {desktop()}
          <img src={footerPattern} alt="pattern" className="px-16" />
        </>
      ) : (
        <>
          {mobile()}
          <img src={footerPattern} alt="pattern" className="px-6" />
        </>
      )}
    </div>
  );
}

export default Section5Videos;
