import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { NonAuthRoutes } from "../../helpers/urls";
import { useWindowResize } from "../../helpers/useWindowResize";
import { ReactComponent as AtegbeLogo } from "../../assets/svg/header/ategbe-logo.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/header/menu-icon.svg";
import { ReactComponent as LanguageIcon } from "../../assets/svg/header/language-icon.svg";
import Buttons from "../buttons/buttons";
import { LanguageContext } from "../../utils/context";

function Header({ setMobileMenuOpen }) {
  const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
  const { width } = useWindowResize();

  /** handles selection from language dialog */
  const handleSelectLanguage = () => {
    localStorage.removeItem("currentLanguage");
    setCurrentLanguage(null);
  };

  // const navigate = useNavigate();

  /** Displays Header Desktop */
  const headerDesktop = () => {
    return (
      <div className="w-full h-[79px] flex items-center justify-between px-[121px] md:px-[60px]">
        {/* <div className="flex items-end cursor-pointer"> */}
        <AtegbeLogo title="Ategbe Movement" className="h-[59px] w-[249px]" />
        {/* </div> */}
        <div className="w-1/2 flex items-center justify-between">
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese" ? "Lar" : "Home"
              }
              specificButtonText={
                currentLanguage === "portuguese" ? "Lar" : "Home"
              }
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese" ? "Sobre" : "About"
              }
              specificButtonText={
                currentLanguage === "portuguese" ? "Sobre" : "About"
              }
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese" ? "Recursos" : "Resources"
              }
              specificButtonText={
                currentLanguage === "portuguese" ? "Recursos" : "Resources"
              }
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese"
                  ? "Participe ao vivo"
                  : "Join Live"
              }
              specificButtonText={
                currentLanguage === "portuguese"
                  ? "Participe ao vivo"
                  : "Join Live"
              }
              buttonDotSymbolStyling="text-xl text-[#FF0000]"
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese" ? "Percorrer" : "Tour"
              }
              specificButtonText={
                currentLanguage === "portuguese" ? "Percorrer" : "Tour"
              }
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <div>
            <Buttons
              specificButtonTitle={
                currentLanguage === "portuguese" ? "Contacto" : "Contact"
              }
              specificButtonText={
                currentLanguage === "portuguese" ? "Contacto" : "Contact"
              }
              specificButtonTextStyling="font-FaktumRegular text-sm text-ategbe-font-white hover:text-ategbe-green lg:whitespace-nowrap"
              // specificButtonClick={null}
            />
          </div>
          <Buttons
            specificButtonTitle="Language"
            specificButtonIcon={<LanguageIcon className="w-[35px] h-[35px]" />}
            specificButtonClick={() => handleSelectLanguage()}
          />
        </div>
      </div>
    );
  };

  /** Displays Header Mobile */
  const headerMobile = () => {
    return (
      <div className="w-full h-[76px] flex items-center justify-between px-[16px]">
        <div className="flex items-center justify-between">
          <AtegbeLogo title="Ategbe Movement" className="w-[121px] h-[41px]" />
        </div>
        <div>
          <Buttons
            specificButtonTitle="Language"
            specificButtonStyling="mr-[6px]"
            specificButtonTextStyling=""
            specificButtonIcon={<LanguageIcon className="w-[30px] mb-[3px]" />}
            specificButtonClick={() => handleSelectLanguage()}
          />
          <Buttons
            specificButtonTitle="Menu"
            specificButtonStyling=""
            specificButtonTextStyling=""
            specificButtonIcon={<MenuIcon className="w-[40px] h-[40px]" />}
            specificButtonClick={() => setMobileMenuOpen(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="sticky top-0 z-30 bg-ategbe-background-black">
      {width > 1060 ? <>{headerDesktop()}</> : <>{headerMobile()}</>}
    </div>
  );
}

export default Header;
