import React, { useEffect, useState } from "react";
import MobileMenu from "../../components/mobile-menu/mobile-menu";
import Header from "../../components/header/header";
import Section1Hero from "./section-1-hero";
import Section2About from "./section-2-about";
import leftPattern from "../../assets/img/left-side-pattern.png";
import rightPattern from "../../assets/img/right-side-pattern.png";
import Section3Resources from "./section-3-resources";
import Section4Live from "./section-4-live";
import Section6Contact from "./section-6-contact";
import Footer from "../../components/footer/footer";
import Section5Videos from "./section-5-videos";

function LandingPage() {
  // const refAbout = useRef(null);
  // const refResources = useRef(null);
  // const refReviews = useRef(null);
  // const refDownload = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const ac = new AbortController();

    document.title = "Ategbe Movement • Home";
    window.scrollTo({ top: 0, behavior: "auto" });

    return function cleanup() {
      ac.abort();
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${leftPattern})`,
        backgroundRepeat: "repeat-y",
        backgroundSize: "5%",
        backgroundPosition: "left 6%",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${rightPattern})`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "5%",
          backgroundPosition: "right ",
        }}
      >
        <MobileMenu
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        <Header setMobileMenuOpen={setMobileMenuOpen} />
        <section>
          <Section1Hero />
        </section>
        <section>
          <Section2About />
        </section>
        <section>
          <Section3Resources />
        </section>
        <section>
          <Section4Live />
        </section>
        <section>
          <Section5Videos />
        </section>
        <section>
          <Section6Contact />
        </section>
        <section>
          <Footer />
        </section>
      </div>
    </div>
  );
}

export default LandingPage;
